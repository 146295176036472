import { faCircleInfo, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextInput, Tooltip } from 'flowbite-react';
import React, { FunctionComponent } from 'react';

import { isNullish } from '../../helpers/typeGuards';

interface Props {
    label: string;
    type: string;
    value?: string | number;
    placeholder?: string;
    tooltip?: string;
    tooltipIcon?: IconDefinition;
    tooltipIconClass?: string;
    onChange?: (value: string) => void;
    disable?: boolean;
}

export const InputText: FunctionComponent<Props> = ({ 
    label, 
    type = 'text',
    value = '', 
    placeholder = '', 
    tooltip = undefined, 
    tooltipIcon, 
    tooltipIconClass, 
    disable = false, 
    onChange,
}) => {
    const displayToolTip = () => {
        if (isNullish(tooltip)) {
            return null;
        }
        return (
            <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center">
                <Tooltip content={tooltip} placement="top">
                    <FontAwesomeIcon icon={tooltipIcon || faCircleInfo} className={tooltipIconClass}/>
                </Tooltip>
            </div>
        );
    };

    return (
        <div>
            <div className="mb-2 block z-[10]">
                <div className="inline-flex items-center justify-center">
                    <label className="block text-gray-700 text-sm font-bold mb-2">{label}</label>
                    {displayToolTip()}
                </div>
                <div className="sm:max-w-[600px] w-full">
                    <TextInput
                        id="txtInput"
                        type={type}
                        placeholder={placeholder}
                        disabled={disable}
                        width="500px"
                        value={value || ''}
                        onChange={(e)=>onChange && onChange(e.target.value.toString())}
                    />
                </div>
            </div>

        </div>
    );
};