const debug: typeof console.log = (...parameters) => {
    if (process.env.REACT_APP_STAGE === 'development') {
        // eslint-disable-next-line no-console
        console.log(...parameters);
    }
};

const delay = (time: number) => {
    return new Promise(resolve => setTimeout(resolve, time));
};

const countLength = (str: string) => {
    let len = 0;
    for (let i = 0; i < str.length; i++) {
        const c = str.charCodeAt(i);
        // Shift_JIS: 0x0 ～ 0x80, 0xa0 , 0xa1 ～ 0xdf , 0xfd ～ 0xff
        // Unicode : 0x0 ～ 0x80, 0xf8f0, 0xff61 ～ 0xff9f, 0xf8f1 ～ 0xf8f3
        if ((c >= 0x0 && c < 0x81) || (c === 0xf8f0) || (c >= 0xff61 && c < 0xffa0) || (c >= 0xf8f1 && c < 0xf8f4)) {
            len += 1;
        } else {
            len += 2;
        }
    }
    return len;
};

const checkFullWidth = (str: string) => {
    return str.length * 2 == countLength(str);
};

const getParameters = (hash: string) => {
    const result: { [key: string]: string } = {};
    for (const keyValue of hash.slice(1).split('&')) {
        const [ key, value ] = keyValue.split('=');
        result[key] = value;
    }
    return result;
};

const checkAlphabet = (str: string) => {
    for (let i = 0; i < str.length; i++) {
        const c = str[i];
        if (!/^[a-zA-Z]+$/.test(c)) {
            return false;
        }
    }
    return true;
};

const range = (start: number, end: number, step=1) => {
    const a:number[] = [ start ];
    let b = start;
    while (b < end) {
        a.push(b += step);
    }
    return a;
};

export {
    debug,
    delay,
    checkFullWidth,
    checkAlphabet,
    getParameters,
    range,
};
