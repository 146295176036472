import React, { createContext, FC, useContext, useState } from 'react';

import { PopupModal } from '../../components/common/PopupModal';
import { ChildrenProps } from '../../types/props';

interface ConfirmContent {
    show: boolean;
    title?:string;
    message:string;
    okLabel?:string;
    cancelLabel?:string;
    onOK?(): void;
    onCancel?():void;
}
interface ConfirmModal {
    showConfirmation?(content:ConfirmContent):void;
}

const ConfirmModalContext  = createContext<ConfirmModal>({});

const useConfirm= () => useContext(ConfirmModalContext);


const ConfirmationProvider:FC<ChildrenProps> = ({ children }) => {
    const [ confirmContent, setConfirmContent ] = useState<ConfirmContent>({ show:false, title:'', message:'' });

    const showConfirmation = (content:ConfirmContent)=>{
        setConfirmContent({ ...confirmContent, ...content });
    };

    const onOK = ()=>{
        if(confirmContent.onOK){ confirmContent.onOK(); }
        setConfirmContent({ ...confirmContent, show:false });
    };

    const onCancel = ()=>{
        if(confirmContent.onCancel){ confirmContent.onCancel(); }
        setConfirmContent({ ...confirmContent, show:false });
    };

    return (
        <ConfirmModalContext.Provider value={{ showConfirmation }}>
            <PopupModal
                show={confirmContent?.show}
                title={confirmContent?.title}
                okLabel={confirmContent?.okLabel}
                onOK={onOK}
                cancelLabel={confirmContent?.cancelLabel}
                onCancel={onCancel}
            >
                <label>{confirmContent?.message}</label>
            </PopupModal>
            {children}
        </ConfirmModalContext.Provider>
    );
};

export {
    ConfirmModalContext,
    ConfirmationProvider,
    useConfirm,
};