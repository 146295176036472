import { Button, Modal } from 'flowbite-react';
import React, { FunctionComponent } from 'react';

import { ChildrenProps } from '../../types/props';

interface Props extends ChildrenProps {
    show?: boolean;
    title?: string;
    okLabel?: string;
    cancelLabel?: string;
    onOK(): void;
    onCancel(): void;
}
export const PopupModal: FunctionComponent<Props> = ({
    children,
    show = false,
    title,
    okLabel,
    cancelLabel,
    onOK,
    onCancel,
}) => {
    return (
        <Modal
            show={show}
            size="md"
            popup={true}
            onClose={onCancel}
        >
            <Modal.Header>{title}</Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                        {children}
                    </h3>
                    <div className="flex justify-center gap-4">
                        <Button
                            color="failure"
                            onClick={onOK}
                        >
                            {okLabel}
                        </Button>
                        <Button
                            color="gray"
                            onClick={onCancel}
                        >
                            {cancelLabel}
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};