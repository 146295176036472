type TypeGuard<GuardedType> = (value: unknown) => value is GuardedType;

const not = <ItemType>(typeGuard: TypeGuard<ItemType>) => <T>(value: T | ItemType): value is T => !typeGuard(value);

const isNull = (value: unknown): value is null => value === null;
const isUndefined = (value: unknown): value is undefined => typeof value === 'undefined';
const isNullish = (value: unknown): value is null | undefined => isNull(value) || isUndefined(value);

const isNonNull = not(isNull);
const isDefined = not(isUndefined);
const hasValue = not(isNullish);

const isString = (value: unknown): value is string => typeof value === 'string';
const isNumber = (value: unknown): value is number => typeof value === 'number' && !isNaN(value);
const isBoolean = (value: unknown): value is boolean => typeof value === 'boolean';

const isArray = (value: unknown): value is unknown[] => Array.isArray(value);
const isArrayOf = <ItemType>(typeGuard: TypeGuard<ItemType>) => (value: unknown): value is ItemType[] => {
    return isArray(value)
        && value
            .map(item => typeGuard(item))
            .reduce((isFullyGuarded, isItemGuarded) => isFullyGuarded && isItemGuarded, true);
};

const isObject = (value: unknown): value is Record<string, unknown> => !isNull(value) && typeof value === 'object';

const isOneOf = <Type>(list: readonly Type[]) => (value: unknown): value is Type => {
    return list.some(item => value === item);
};

export {
    TypeGuard,

    isNull,
    isUndefined,
    isNullish,

    isNonNull,
    isDefined,
    hasValue,

    isString,
    isNumber,
    isBoolean,

    not,

    isArray,
    isArrayOf,

    isObject,

    isOneOf,
};
