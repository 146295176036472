import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';

const retryCodes = [ 'ENOTFOUND', '408', '500', '502', '503', '504', '522', '524' ];

type RequestError = {
    status?: string | undefined;
    message?: string;
};

type RequestMethod = 'get' | 'post' | 'put' | 'delete' | 'options';

export default class Request {
    private mOriginalURL = '';
    private mBaseURL = '';
    private mHeaders: object = {};
    private mBody: object = {};
    private mURL = '';
    private mMethod: RequestMethod = 'get';

    constructor(entryURL = '') {
        const { protocol, port, hostname } = window.location;
        this.mOriginalURL = entryURL || `${protocol}//${hostname}:${port}`;
    }

    connect(url = '') {
        //we reset all params when start to connect
        this.mBaseURL = this.mOriginalURL;
        this.mHeaders = {};
        this.mBody = {};
        this.mURL = url;
        this.mMethod = 'get'; //default
        return this;
    }

    setHeaders(value: object) {
        this.mHeaders = value;
        return this;
    }

    setBody(value: object) {
        this.mBody = value;
        return this;
    }

    get(maxRetryTime=0) {
        this.mMethod = 'get';
        return this.request(maxRetryTime);
    }

    post(maxRetryTime=0) {
        this.mMethod = 'post';
        return this.request(maxRetryTime);
    }

    put(maxRetryTime=0) {
        this.mMethod = 'put';
        return this.request(maxRetryTime);
    }

    delete(maxRetryTime=0) {
        this.mMethod = 'delete';
        return this.request(maxRetryTime);
    }

    request(maxRetryTime = 0) {
        return new Promise((onSuccess, onError) => {
            const requestRetry = (retryTime = 0) => {
                const params: AxiosRequestConfig = {
                    method: this.mMethod,
                    url: this.mURL,
                    headers: {
                        ...this.mHeaders,
                    },
                    timeout: 30000,
                    data: this.mBody,
                };
                if (!this.mURL.startsWith('http')) {
                    params.baseURL = this.mBaseURL;
                }
                axios(params)
                    .then((response: AxiosResponse) => {
                        onSuccess(response.data);
                    })
                    .catch((error: AxiosError) => {
                        const errorCode = error.code || '400';
                        if (retryTime > 0 && retryCodes.includes(errorCode)) {
                            setTimeout(() => {
                                requestRetry(retryTime - 1);
                            }, 3000);
                        } else {
                            // Return error code
                            const errorContent: RequestError = {
                                status: errorCode,
                                message: (error.response && error.response.data) || error.message,
                            };
                            onError(errorContent);
                        }
                    });
            };
            requestRetry(maxRetryTime);
        });
    }
}