import i18n, { InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { en } from './locales/en';
import { ja } from './locales/ja';

const translations = { en, ja };

const initOptions: InitOptions = {
    debug: true,
    fallbackLng: process.env.REACT_APP_DEFAULT_LANG || 'en',
    interpolation: { escapeValue: false },
    resources: translations,
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(initOptions)
    .then();

export {
    translations,
};

export default i18n;