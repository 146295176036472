import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { registerUser } from '../apis/UserAPI';
import { Intro } from '../components/Intro';
import { RegisterForm } from '../components/RegisterForm';
import { ResultForm } from '../components/ResultForm';
import { useLoading } from '../helpers/hooks/useLoading';
import { notify } from '../helpers/notify';
import { getParameters } from '../helpers/utils';
import { RegisterInfo } from '../types/api';
import { Layout } from './Layout';

type SceneMode = 'Register' | 'Result';
export const RegisterPage = () => {
    const { t } = useTranslation();
    const location = useLocation(); 
    const { setLoading } = useLoading();
    const [ callback, setCallBack ] = useState<string>('');
    const [ oauthToken, setOauthToken ] = useState<string>('');
    const [ oauthVerifier, setOauthVerifier ] = useState<string>('');
    const [ mode, setMode ] = useState<SceneMode>('Register');

    useEffect(() => {
        const hash = location.hash || '';
        const parameters = getParameters(hash);
        //Check if there aren't callback, oauth_token and oauth_verifier parameter
        //we will redirect user to the authen API page
        if(!parameters['callback'] || !parameters['oauth_token'] || !parameters['oauth_verifier']){
            window.location.href = process.env.REACT_APP_API_AUTH || '';
            return;
        }
        setCallBack(parameters['callback']);
        setOauthToken(parameters['oauth_token']);
        setOauthVerifier(parameters['oauth_verifier']);
    }, [ location ]);

    const onSubmit = async (info: RegisterInfo) => {
        setLoading(true);
        try {
            await registerUser(callback, oauthToken, oauthVerifier, info);
            setMode('Result');
        } catch (error) {
            notify('ERROR', t('common.processError'));
        }
        setLoading(false);
    };

    return (
        <Layout>
            {
                mode === 'Register' ? <>
                    <Intro />
                    <RegisterForm onSubmit={onSubmit} />
                </> : <ResultForm />
            }
        </Layout>
    );
};
