import { ja } from 'date-fns/locale';
import { Select, TextInput, TextInputProps } from 'flowbite-react';
import React, { useState, useEffect } from 'react';
import DatePicker, { ReactDatePickerCustomHeaderProps, ReactDatePickerProps } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { DATE_FORMAT } from '../../constants';
import { range } from '../../helpers/utils';

interface Props extends ReactDatePickerProps {
    value?: string;
    className?: string;
    placeholder?: string;
    width?: string | number;
}

const ReactDatePickerInput = React.forwardRef<HTMLInputElement, TextInputProps>(
    (props, ref) => <TextInput
        ref={ref}
        {...props}
        style={{ outlineColor: '#1c64f2' }}
        placeholder={props.placeholder || DATE_FORMAT.toUpperCase()} 
    />,
);

ReactDatePickerInput.displayName = 'ReactDatePickerInput';

export const CustomDatePicker = ({ value, onChange, placeholder, className, width, ...props }: Props) => {
    const [ selectedDate, setSelectedDate ] = useState<Date | null>(value ? new Date(value) : null);
    const years = range(1900, new Date().getFullYear() + 1);
    const months = [
        '1月',
        '2月',
        '3月',
        '4月',
        '5月',
        '6月',
        '7月',
        '8月',
        '9月',
        '10月',
        '11月',
        '12月',
    ];

    const renderCustomerHeader = ({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
    }: ReactDatePickerCustomHeaderProps) => {
        return (
            <div className="grid gap-2 grid-cols-[20px_1fr_1fr_20px]">
                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                    {'<'}
                </button>
                <Select id="select" required={true} onChange={(event) => changeYear(parseInt(event.target.value))} value={date.getFullYear()}>
                    {years.map((option) => {
                        return (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        );
                    })}
                </Select>
                <Select sizing="md" id="select" style={{ minWidth: '100px' }} required={true} onChange={(event) => changeMonth(months.indexOf(event.target.value))} value={months[date.getMonth()]}>
                    {months.map((option) => {
                        return (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        );
                    })}
                </Select>
                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                    {'>'}
                </button>
            </div>
        );
    };

    useEffect(() => {
        if (value) {
            setSelectedDate(new Date(value));
        }
    }, [ value ]);

    const handleChangeDate = (date: Date, event: React.SyntheticEvent<unknown> | undefined) => {
        setSelectedDate(date);
        onChange && onChange(date, event);
    };

    return (
        <DatePicker
            className={className}
            dateFormat={DATE_FORMAT}
            renderCustomHeader={renderCustomerHeader}
            selected={selectedDate}
            onChange={handleChangeDate}
            locale={ja}
            {...props}
            customInput={<ReactDatePickerInput
                placeholder={placeholder}
                width={width}
            />}
        />
    );
};
