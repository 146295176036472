import React from 'react';
import { useTranslation } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { App } from './App';
import { ConfirmationProvider } from './helpers/hooks/useConfirm';
import { LoadingProvider } from './helpers/hooks/useLoading';

const queryClient = new QueryClient();
const DEVTOOLS_ON = false;

const Root = () => {
    const baseURL = process.env.REACT_APP_ACAP_BASE_PATH || '';
    const { i18n } = useTranslation();
    i18n.changeLanguage(process.env.REACT_APP_DEFAULT_LANG || 'ja');
    return (
        <BrowserRouter basename={baseURL}>
            <QueryClientProvider client={queryClient}>
                <LoadingProvider>
                    <ConfirmationProvider>
                        <ToastContainer />
                        <App />
                    </ConfirmationProvider>
                </LoadingProvider>
                {process.env.NODE_ENV === 'development' && DEVTOOLS_ON
                    && <ReactQueryDevtools initialIsOpen={false} />
                }
            </QueryClientProvider>
        </BrowserRouter>
    );
};

export { Root };
