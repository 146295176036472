import { useEffect, FunctionComponent } from 'react';

interface Props {
    to?: string;
}
export const RedirectPage: FunctionComponent<Props> = ({ to = '/' }) => {
    useEffect(() => {
        window.location.href = to;
    }, []);

    return null;
};