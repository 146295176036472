import { Button } from 'flowbite-react';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SimpleReactValidator from 'simple-react-validator';

import 'simple-react-validator/dist/locale/ja';
import { DATE_FORMAT } from '../constants';
import { locations } from '../constants/location';
import i18n from '../helpers/i18n';
import { checkAlphabet, checkFullWidth } from '../helpers/utils';
import { RegisterInfo } from '../types/api';
import { InputDate } from './common/InputDate';
import { InputText } from './common/InputText';
import { SelectInput } from './common/SelectInput';

interface Props {
    registerInfo?: RegisterInfo;
    onSubmit?: (info: RegisterInfo) => void;
}

export const RegisterForm: FunctionComponent<Props> = ({
    registerInfo = { contactInfo:{ gender:'unknown' }, jobInfo:{ assignedLocation: locations[0] } },
    onSubmit,
}) => {
    const { t } = useTranslation();
    // Additional Rules using validator
    const additionalRules = {
        full_width:{ message: t('validation.fullWidth'), rule: (val:string) => checkFullWidth(val) || checkAlphabet(val) },
        //ADD MORE RULES HERE LATER
    };
    const validator = useRef(new SimpleReactValidator({ locale:i18n.language, validators: additionalRules }));

    const [ userInfo, setUserInfo ] = useState<RegisterInfo>(registerInfo);
    /**
     * Validate and show message
     * @param name A unique underscored string that gets replaced in the messaging as the name of the field.
     * @param value Usually the state of the current field.
     * @param condition A pipe separated list of rules to check the value.
     * @returns Display html element of message or not
     */
    const showMessage = (name: string, value: unknown, condition: unknown) => {
        return validator.current.message(name, value, condition, {
            className: 'text-yellow-400 mb-2 max-w-[380px]',
            autoForceUpdate: true,
        });
    };

    /** Call when click submit button
     * If all fields in form are valid, save data
     * Otherwise, show the error messages.
     */
    const onSubmitInfo = () => {
        if (validator.current.allValid()) {
            //console.log('You save successful');
            onSubmit && onSubmit(userInfo);
        } else {
            validator.current.showMessages();
        }
    };

    useEffect(() => {
        /** Update user info whenever there are changes */
        setUserInfo(userInfo);
    }, [ userInfo ]);

    useEffect(() => {
        /** Show validation messages when there are validation issues */
        if (!(validator.current && validator.current.allValid())) {
            validator.current.showMessages();
        }
    }, [ userInfo ]);

    return (
        <div className="sm:min-w-[500px] flex justify-center self-center">
            <div className="p-12 bg-white mx-auto rounded-2xl w-full z-10">
                <div className="mt-8 space-y-6">
                    <div className="flex items-center w-full">
                        <img className="m-auto" src="https://garmin-assets.s3.ap-northeast-1.amazonaws.com/scoville.png" />
                    </div>
                    <div className="text-center">
                        <h2 className="mt-6 text-[30px] font-bold text-gray-900 whitespace-pre-line">
                            {t('pages.register.form.title')}
                        </h2>
                    </div>
                    <div>
                        <h3 className="font-semibold text-2xl text-gray-800">{t('pages.register.form.contactInfo')}</h3>
                        <InputText
                            label={t('pages.register.form.familyName')}
                            placeholder={t('pages.register.form.familyNameHolder')}
                            type="text"
                            value={userInfo.contactInfo?.familyName}
                            onChange={(value) => setUserInfo({
                                jobInfo: userInfo.jobInfo,
                                contactInfo: { ...userInfo.contactInfo, familyName: value },
                            })}
                        />
                        {showMessage(t('pages.register.form.familyName'), userInfo.contactInfo?.familyName, 'required|full_width')}
                        <InputText
                            label={t('pages.register.form.givenName')}
                            placeholder={t('pages.register.form.givenNameHolder')}
                            type="text"
                            value={userInfo.contactInfo?.givenName}
                            onChange={(value) => setUserInfo({
                                jobInfo: userInfo.jobInfo,
                                contactInfo: { ...userInfo.contactInfo, givenName: value },
                            })}
                        />
                        {showMessage(t('pages.register.form.givenName'), userInfo.contactInfo?.givenName, 'required|full_width')}
                        <InputText
                            label={t('pages.register.form.email')}
                            placeholder="user@domain.jp"
                            type="email"
                            value={userInfo.contactInfo?.email}
                            onChange={(value) => setUserInfo({
                                jobInfo: userInfo.jobInfo,
                                contactInfo: { ...userInfo.contactInfo, email: value },
                            })}
                        />
                        {showMessage(t('pages.register.form.email'), userInfo.contactInfo?.email, 'required|email')}
                        <InputDate
                            label={t('pages.register.form.birthday')}
                            type="date"
                            value={userInfo.contactInfo?.birthday}
                            maxDate={new Date()}
                            placeholder={DATE_FORMAT.toUpperCase()}
                            onChange={(value) => setUserInfo({
                                jobInfo: userInfo.jobInfo,
                                contactInfo: { ...userInfo.contactInfo, birthday: value },
                            })}
                        />
                        {showMessage(t('pages.register.form.birthday'), userInfo.contactInfo?.birthday, 'required')}
                        <SelectInput
                            label={t('pages.register.form.gender')}
                            value={userInfo.contactInfo?.gender}
                            labels={[ t('gender.unknown'), t('gender.male'), t('gender.female') ]}
                            values={[ 'unknown', 'male', 'female' ]}
                            onChange={(value) => setUserInfo({
                                jobInfo: userInfo.jobInfo,
                                contactInfo: { ...userInfo.contactInfo, gender: value },
                            })}
                        />
                        {showMessage(t('pages.register.form.gender'), userInfo.contactInfo?.gender, 'required')}
                    </div>
                    <div>
                        <h3 className="font-semibold text-2xl text-gray-800">{t('pages.register.form.jobInfo')}</h3>
                        <InputText
                            label={t('pages.register.form.employeeId')}
                            placeholder="1234"
                            type="number"
                            value={userInfo.jobInfo?.employeeId}
                            onChange={(value:string) => setUserInfo({
                                contactInfo: userInfo.contactInfo,
                                jobInfo: { ...userInfo.jobInfo, employeeId: value },
                            })}
                        />
                        {showMessage(t('pages.register.form.employeeId'), userInfo.jobInfo?.employeeId, 'required|number')}
                        <InputDate
                            label={t('pages.register.form.dateOfHire')}
                            type="date"
                            value={userInfo.jobInfo?.dateOfHire}
                            placeholder={DATE_FORMAT.toUpperCase()}
                            onChange={(value:string) => setUserInfo({
                                contactInfo: userInfo.contactInfo,
                                jobInfo: { ...userInfo.jobInfo, dateOfHire: value },
                            })}
                        />
                        {showMessage(t('pages.register.form.dateOfHire'), userInfo.jobInfo?.dateOfHire, 'required')}
                        <SelectInput
                            label={t('pages.register.form.assignedLocation')}
                            value={userInfo.jobInfo?.assignedLocation}
                            values={locations}
                            onChange={(value) => setUserInfo({
                                contactInfo: userInfo.contactInfo,
                                jobInfo: { ...userInfo.jobInfo, assignedLocation: value },
                            })}
                        />
                        {showMessage(t('pages.register.form.assignedLocation'), userInfo.jobInfo?.assignedLocation, 'required')}
                        <InputDate
                            label={t('pages.register.form.dateOfAssignment')}
                            type="date"
                            value={userInfo.jobInfo?.dateOfAssignment}
                            placeholder={DATE_FORMAT.toUpperCase()}
                            onChange={(value:string) => setUserInfo({
                                contactInfo: userInfo.contactInfo,
                                jobInfo: { ...userInfo.jobInfo, dateOfAssignment: value },
                            })}
                        />
                        {showMessage(t('pages.register.form.dateOfAssignment'), userInfo.jobInfo?.dateOfAssignment, 'required')}
                    </div>
                    <div>
                        <Button
                            disabled={!(validator.current && validator.current.allValid())}
                            onClick={()=>onSubmitInfo()}
                        >
                            {t('button.submit')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
