import React, { FunctionComponent } from 'react';

import { ChildrenProps } from '../../types/props';

export const Layout: FunctionComponent<ChildrenProps> = ({ children }) => {
    return (
        <div className="bg-no-repeat bg-cover bg-center relative">
            <div className="absolute bg-gradient-to-b from-indigo-600 to-blue-500 opacity-75 inset-0 z-0"></div>
            <div className="min-h-screen sm:flex sm:flex-row mx-0 justify-center">
                {children}
            </div >
            <ul className="circles">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
        </div >
    );
};