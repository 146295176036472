import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { RedirectPage } from './pages/RedirectPage';
import { RegisterPage } from './pages/RegisterPage';

const App = () => {
    return (
        <Routes>
            <Route path="/registration" element={<RegisterPage />} />
            <Route path="*" element={<RedirectPage to={process.env.REACT_APP_API_AUTH} />} />
        </Routes>
    );
};

export {
    App,
};
