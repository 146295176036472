export const en = {
    translation: {
        validation:{
            fullWidth: 'The :attribute must be full width.',
        },
        button: {
            ok: 'Ok',
            yes: 'Yes',
            no: 'No',
            cancel: 'Cancel',
            save: 'Save',
            confirm: 'Confirm',
            create: 'Create',
            submit: 'Submit',
        },
        common: {
            processSuccess: 'Data has been processed successfully',
            processError: 'There was an error handling your request',
        },
        pages:{
            register:{
                intro:{
                    content: `By connecting with Security Guard Health Check, 
                    you agree to share information from your Garmin Connect 
                    account to enhance your experience with Security Guard Health Check. 
                    This may include activities, location, heart rate and related metrics, 
                    calories burned and other health or personal data.
                    Do you agree to share information from your Garmin Connect 
                    account with Security Guard Health Check? You can opt out 
                    and disconnect from Security Guard Health Check at any 
                    time in your Garmin Connect settings.`,
                },
                form:{
                    title: 'Security Guard Registration',
                    contactInfo: 'Contact Information',
                    familyName: 'Family Name',
                    familyNameHolder: 'Tanaka',
                    givenName: 'Given Name',
                    givenNameHolder: 'Taro',
                    email: 'E-mail',
                    birthday: 'Birthday',
                    gender: 'Gender',
                    jobInfo: 'Job Information',
                    employeeId: 'Employee ID',
                    dateOfHire: 'Date of Hire',
                    assignedLocation: 'Assigned Location',
                    dateOfAssignment: 'Date of Assignment',
                    agree: 'I agree with the Terms and Conditions',
                },
                result:{
                    title:'Thank You!',
                    description:'Connection with Garmin is complete.',
                    home:'Home',
                },
            },
        },
        gender:{
            unknown: 'Rather not say',
            male: 'Male',
            female: 'Female',
        },
    },
};