import { init } from '@sentry/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import 'react-toastify/dist/ReactToastify.css';

import { isNullish, isUndefined } from './helpers/typeGuards';
import { Root } from './Root';
import './helpers/i18n';
import './styles/root.css';


init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    maxBreadcrumbs: 50,
    debug: false,
    environment: process.env.REACT_APP_STAGE,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    sampleRate: 1.0,
    normalizeDepth: 11, // when logging contexts, allow many layers to be displayed
    // https://github.com/getsentry/sentry-javascript/issues/1964
    ignoreErrors: [ 'ResizeObserver loop limit exceeded' ],
});

if (isUndefined(process.env.REACT_APP_SENTRY_DSN)) {
    throw Error('The REACT_APP_SENTRY_DSN is not defined so Sentry will not work.');
}

const rootElementId = 'root';
const rootElement = document.getElementById(rootElementId);

if (isNullish(rootElement)) { 
    throw new Error(`Cannot find the "#${rootElementId}" element.`);
}

const root = createRoot(rootElement);
root.render(<Root />);
