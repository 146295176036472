export const locations = [
    '東京経済大学',
    '東京経済大学（１００周年記念会館）',
    '東京経済大学国際交流会館',
    '東京経済大学（外構）',
    'SMC（株）筑波第一工場',
    'SMC（株）筑波第二工場',
    'SMC（株）筑波技術センター',
    'SMC（株）下妻工場',
    'SMC（株）草加第一工場',
    'SMC（株）草加第二工場',
    'THE COURT神宮外苑',
    '貞静学園中学高等学校',
    '貞静学園短期大学',
    '中央大学市ヶ谷田町キャンパス',
    '中央大学市ヶ谷キャンパス',
    'さいたま赤十字病院',
    'さいたま赤十字病院医療社会事業',
    'さいたま赤十字病院救急部業務',
    'ザ・ヨコハマタワーズ',
    'パークコート赤坂檜町ザ タワー',
    'パークコート赤坂ザ タワー',
    'パークコート文京小石川ザ タワー',
    'パークシティ武蔵小山ザ タワー',
    'ニュートンプレイス',
    'アシックスジャパン',
    '大森赤十字病院',
    '晴海トリトンスクエア',
    '九段坂病院',
    '新宿マインズタワー',
    '東横INN東京駅新大橋前',
    '小川赤十字病院',
    'アルファスシティ大島',
    'ヒューリック銀座数寄屋橋ビル',
    'リソー教育城北本部ビル',
    '日本発条 駒ヶ根工場',
    '岡本物流（株）柳橋事業所',
    '深谷赤十字病院',
    'NTT白髭ビル',
    '帝京平成大学',
    '秋葉原ラジオ会館',
    'イーストコモンズ清澄白河',
    'オーク表参道',
    'グランドメゾン品川シーサイドの杜',
    'トワイシア用賀',
    'ガーデンセシア',
    'ザ・タワーグランディア',
    'かがやきプラザ',
    'ホーチキ生産統轄部',
    'ユーキャン代々木ビル',
    'ホーチキ本社ビル',
    '板橋区医師会病院',
    'ミドリ安全本社ビル',
    'モアクレスト荒川公園',
    'GLA総合本部本館',
    '葛飾通勤寮',
    'IHIターボ',
    '東京地下鉄（株）鷺沼車両基地',
    '東京宝くじドリーム館',
    '日鐵木挽ビル',
    '丸の内三井ビル',
    '亀有病院',
    '思い出ビル',
    '玉の肌石鹸（株）本社ビル',
    '山脇服飾美術学院ビル',
    '葛飾リハビリテーション病院',
    '一般社団法人倫理研究所本部',
    '輝山会記念病院',
    'エルシーブイ',
    'スカイフォレストレジデンス',
    'ミクナスエンジニアリング',
    'ナイアンティック',
    'ビューウェルスクエア',
    '日本経営協会',
    'エステー',
    '三菱電機（株）社長身辺警護',
    'JFE商事株式会社',
    'フクダ電子（株）本郷事務所',
    '長野巡回',
    '茅野地区 夜間巡回警備',
    '芝浦アークビル',
];