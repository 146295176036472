import { Select } from 'flowbite-react';
import React, { FunctionComponent } from 'react';

interface Props {
    label: string;
    value?: string;
    values: string[];
    labels?: string[];
    onChange: (value: string) => void;
}
export const SelectInput: FunctionComponent<Props> = ({ label, value, values, labels, onChange }) => {
    return (
        <div id="select">
            <div className="mb-2 block">
                <div className="inline-flex items-center justify-center">
                    <label className="block text-gray-700 text-sm font-bold mb-2">{label}</label>
                </div>

                <div className="md:max-w-[600px] w-full">
                    <Select id="select" required={true} onChange={(event) => onChange(event.target.value)} value={value}>
                        {values && values.map((obj: string, index: number) => {
                            return (
                                <option key={index} value={obj}>
                                    {labels?labels[index]:obj}
                                </option>
                            );
                        })}
                    </Select>
                </div>
            </div>
        </div>
    );
};