import React from 'react';
import { useTranslation } from 'react-i18next';

export const Intro = () => {
    const { t } = useTranslation();
    return (
        <div className="flex-col self-center p-10 sm:max-w-5xl xl:max-w-2xl  z-10 override-hidden md:block">
            <div className="self-start lg:flex flex-col  text-white p-[50px]">
                <img src="" className="mb-3" />
                <h1 className="sm:text-4xl xl:text-5xl font-bold leading-tight mb-6">Nissho Keibi </h1>
                <div className="sm:text-sm xl:text-md text-gray-200 font-normal">
                    {t('pages.register.intro.content')}
                </div>
            </div>
        </div>
    );
};
