export const ja = {
    translation: {
        validation:{
            fullWidth: ':attributeは全角のみを入力してください。',
        },
        button: {
            ok: 'Ok',
            yes: 'はい',
            no: 'いいえ',
            cancel: 'キャンセル',
            save: '保存',
            confirm: '確認',
            create: '作成',
            submit: '登録する',
        },
        common: {
            processSuccess: '正常に処理できました',
            processError: '正常に処理できませんでした',
        },
        pages:{
            register:{
                intro:{
                    content: `Security Guard Health Check に接続することにより、
                    Garmin Connect アカウントからの情報を共有して、
                    Security Guard Health Check のエクスペリエンスを向上
                    させることに同意したことになります。
                    これには、アクティビティ、場所、心拍数および関連する指標、
                    消費カロリー、その他の健康データまたは個人データが含まれる場合があります。
                    Garmin Connect アカウントの情報を Security Guard Health Check と
                    共有することに同意しますか? Garmin Connect 設定でいつでも 
                    Security Guard Health Check をオプトアウトして切断できます。`,
                },
                form:{
                    title: '警備員登録',
                    contactInfo: '警備員情報',
                    familyName: '姓',
                    familyNameHolder: '田中',
                    givenName: '名',
                    givenNameHolder: '太郎',
                    email: 'メールアドレス',
                    birthday: '生年月日',
                    gender: '性別',
                    jobInfo: '配属情報',
                    employeeId: '社員番号',
                    dateOfHire: '入社日',
                    assignedLocation: '配属現場',
                    dateOfAssignment: '配属日',
                    agree: '利用規約に同意します',
                },
                result:{
                    title:'ありがとう！',
                    description:'ご関心をお寄せいただきありがとうございます！\nGarminとの接続が完了しました。',
                    home:'戻る',
                },
            },
        },
        gender:{
            unknown: '秘密',
            male: '男性',
            female: '女性',
        },
    },
};