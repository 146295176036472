import { RegisterInfo } from '../types/api';
import Request from './BaseAPI';

export const registerUser = async (callback:string, token:string, verifier:string, info:RegisterInfo) => {
    let url = callback || process.env.REACT_APP_API_CALLBACK || '';
    url += `?oauth_token=${token}`;
    url += `&oauth_verifier=${verifier}`;
    url += `&family_name=${info.contactInfo?.familyName}`;
    url += `&given_name=${info.contactInfo?.givenName}`;
    url += `&email=${info.contactInfo?.email}`;
    url += `&birthday=${info.contactInfo?.birthday}`;
    url += `&gender=${info.contactInfo?.gender}`;
    url += `&employee_id=${info.jobInfo?.employeeId}`;
    url += `&hire_date=${info.jobInfo?.dateOfHire}`;
    url += `&assignment_date=${info.jobInfo?.dateOfAssignment}`;
    url += `&location=${info.jobInfo?.assignedLocation}`;
    const result = await new Request()
        .connect(url)
        .get();
    return result;
};