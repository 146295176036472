import { toast, ToastOptions } from 'react-toastify';

type Level = 'INFO' | 'SUCCESS' | 'WARNING' | 'ERROR';

const defaultConfig: ToastOptions = {
    position: 'top-right',
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: 'colored',
};

const notify = (level:Level, message: string, config: ToastOptions = defaultConfig)=>{
    switch(level){
        case 'INFO': toast.info(message, config); break;
        case 'SUCCESS': toast.success(message, config); break;
        case 'WARNING': toast.warn(message, config); break;
        case 'ERROR': toast.error(message, config); break;
        default: toast(message, config); break;
    }
};

export { Level, notify };
